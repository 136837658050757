import { render, staticRenderFns } from "./HeaderUK.vue?vue&type=template&id=504d8c1f&scoped=true&"
import script from "./HeaderUK.vue?vue&type=script&lang=js&"
export * from "./HeaderUK.vue?vue&type=script&lang=js&"
import style0 from "./HeaderUK.vue?vue&type=style&index=0&id=504d8c1f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504d8c1f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIOpeningTimes: require('/opt/build/repo/components/UI/OpeningTimes.vue').default,CartCountdownAlt: require('/opt/build/repo/components/Cart/CountdownAlt.vue').default})
