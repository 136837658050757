
import { mapGetters } from 'vuex'
import bannerData from "@/data/banner.json";
import optionsData from '@/data/options.json';

export default {
  data() {
    return { 
      banner: {},
      options: optionsData
    };
  },
  async fetch() {
    this.banner = await bannerData;
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    region(){
      let regionVar =  this.userRegion === 'default' ? this.detectedRegion : this.userRegion
      if(!regionVar){
        regionVar = this.$cookies.get('tefl_geolocation_region');
      }
      return regionVar
    },
    bgColour(){
      return this.region === 'en-us' ? this.banner.banner_background_color_us : this.banner.banner_background_color
    },
    buttonColour(){
      return this.region === 'en-us' ? this.banner.banner_button_primary_color_us : this.banner.banner_button_primary_color
    },
    offerLink(){
      return this.region === 'en-us' ? this.banner.offer_url_us_bf : this.banner.offer_url_bf
    },
    offerText(){
      return this.region === 'en-us' ? this.banner.offer_text_us_bf : this.banner.offer_text_bf
    },
    offerAmount(){
      return this.region === 'en-us' ? this.banner.discount_amount_us_bf : this.banner.discount_amount_bf
    },
  },
};
